import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface tokenPayload {
  value: string | null;
  userId: string;
  email: string;
}

export interface tokenState extends tokenPayload {
  isError: boolean;
  isLoading: boolean;
}

const initialState: tokenState = {
  userId: "",
  email: "",
  value: null,
  isError: false,
  isLoading: false,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    TOKEN_REQUEST: (state) => {
      state.isLoading = true;
    },
    TOKEN_SUCCESS: (state, action: PayloadAction<tokenPayload>) => {
      state.value = action.payload.value;
      state.userId = action.payload.userId;
      state.email = action.payload.email;
      state.isLoading = false;
    },
    TOKEN_FAIL: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const { TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAIL } = tokenSlice.actions;

export default tokenSlice.reducer;
