import "./App.css";
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { Suspense } from "react";
import { homePage, landingPage } from "generic-utilities/constants/pages";
import { HashRouter } from "react-router-dom";
import { tmsPage } from "app/modules/constants";

const Home = React.lazy(() => import("app/home/Home"));
const Landing = React.lazy(() => import("app/landing/Landing"));
const ProtectedRoute = React.lazy(
  () => import("generic-utilities/auth/ProtectedRoute")
);

const Tms = React.lazy(() => import("app/modules/tms/Tms"));
const TmsUpdateDelete = React.lazy(
  () => import("app/modules/tms/TmsUpdateDelete")
);

const protectedRoutes = [
  // Home
  {
    path: homePage,
    element: Home,
  },
  { path: tmsPage, element: Tms },
  {
    path: tmsPage + "/:tmsID",
    element: TmsUpdateDelete,
  },
];

const App = () => {
  return (
    <HashRouter>
      {/* <Router> */}
      <Suspense fallback={<></>}>
        <Routes>
          {/* PROTECTED ROUTES */}
          <Route element={<ProtectedRoute navbarTitle="WORK ORDER UI - " />}>
            {protectedRoutes.map((e, idx) => {
              return <Route key={idx} path={e.path} element={<e.element />} />;
            })}
            <Route path="*" element={<Navigate to={homePage} />} />
          </Route>
          {/* PUBLIC ROUTES */}
          <Route path={landingPage} element={<Landing />} />
        </Routes>
      </Suspense>
      {/* </Router> */}
    </HashRouter>
  );
};

export default App;
