import { configureStore } from "@reduxjs/toolkit";
import tokenReducer from "../reducers/token";
import userInfoReducer from "../reducers/userInfo";
import messageReducer from "../reducers/message";
import roleInfoReducer from "../reducers/roleInfo";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ENVIRONMENT } from "generic-utilities/constants/configs";

const reducer = {
  token: tokenReducer,
  userInfo: userInfoReducer,
  message: messageReducer,
  roleInfo: roleInfoReducer,
};

let store = configureStore({
  reducer,
  devTools: ENVIRONMENT === "development" ? true : false,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
