import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface messagePayload {
  message: string;
  type: string;
}

const initialState: { messages: messagePayload[] } = {
  messages: [],
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    CREATE_MESSAGE: (state, action: PayloadAction<messagePayload>) => {
      state.messages.push({
        message: action.payload.message,
        type: action.payload.type,
      });
    },
  },
});

export const { CREATE_MESSAGE } = messageSlice.actions;

export default messageSlice.reducer;
