import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userInfoObj } from "../../api/auth";

export interface userInfoState {
  info: userInfoObj | null;
  isError: boolean;
  isLoading: boolean;
}

const initialState: userInfoState = {
  info: null,
  isError: false,
  isLoading: false,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    USER_INFO_REQUEST: (state) => {
      state.isLoading = true;
    },
    USER_INFO_SUCCESS: (state, action: PayloadAction<userInfoObj>) => {
      state.info = action.payload;
      state.isLoading = false;
    },
    USER_INFO_FAIL: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const { USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAIL } =
  userInfoSlice.actions;

export default userInfoSlice.reducer;
