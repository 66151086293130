import { GATEWAY_URL } from "generic-utilities/constants/configs";

export const tmsPage = "/tms";
export const WO_PERMISSION_PREFIX = "wo_service_api";

export const LIST_TMS = `${GATEWAY_URL}/work_order/list-tms`;
export const CREATE_TMS = `${GATEWAY_URL}/work_order/create-tms`;
export const GET_TMS = `${GATEWAY_URL}/work_order/get-tms`;
export const UPDATE_TMS = `${GATEWAY_URL}/work_order/update-tms`;
export const DELETE_TMS = `${GATEWAY_URL}/work_order/delete-tms`;
export const BULK_CREATE_TMS = `${GATEWAY_URL}/work_order/bulk-create-tms`;

// SCHEMAS
export const LIST_TMS_SCHEMA = LIST_TMS + "__schema";
export const GET_TMS_SCHEMA = GET_TMS + "__schema";
export const CREATE_TMS_SCHEMA = CREATE_TMS + "__schema";
export const DELETE_TMS_SCHEMA = DELETE_TMS + "__schema";
export const UPDATE_TMS_SCHEMA = UPDATE_TMS + "__schema";
