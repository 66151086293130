import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { roleObj } from "../../api/auth";

export interface roleInfoState {
  info: roleObj[] | null;
  isError: boolean;
  isLoading: boolean;
}

const initialState: roleInfoState = {
  info: null,
  isError: false,
  isLoading: false,
};

export const roleInfoSlice = createSlice({
  name: "roleInfo",
  initialState,
  reducers: {
    ROLE_INFO_REQUEST: (state) => {
      state.isLoading = true;
    },
    ROLE_INFO_SUCCESS: (state, action: PayloadAction<roleObj[]>) => {
      state.info = action.payload;
      state.isLoading = false;
    },
    ROLE_INFO_FAIL: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const { ROLE_INFO_REQUEST, ROLE_INFO_SUCCESS, ROLE_INFO_FAIL } =
  roleInfoSlice.actions;

export default roleInfoSlice.reducer;
